/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #3d3a3a;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2rem 0;
  font-weight: 600;
}

h1 {
  margin: 0 0 2rem 0;
}

h6 {
  margin: 2rem 0 0.5rem 0;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #00306b;
}

a:hover {
  color: #1f60af;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

.btn {
  border-radius: 0;
}

.btn-primary {
  background-color: #00306b;
  border: none;
}

.btn-primary:hover {
  background-color: #1f60af;
}

.plus-overlay {
  position: absolute;
  z-index: 2000;
}

blockquote {
  font-size: 1.3rem;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .plus-overlay img {
    width: 40px;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  position: relative;
  height: 459px;
  padding: 2.5rem 1.5rem;
  background-color: #00306b;
}

.header .plus-overlay {
  right: 0;
  bottom: -54px;
}

.header .container-fluid {
  z-index: 4000;
  position: relative;
}

.header .subpage-banner {
  position: absolute;
  width: 75%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
  border-left: 40px solid #061d39;
  background-size: cover;
}

.header .subpage-banner::after {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.header-homepage {
  height: 800px;
}

.header-homepage .swiper-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1;
}

.header-homepage .swiper-container .swiper-slide {
  background-color: #00306b;
}

.header-homepage .swiper-container .swiper-slide-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  border-left: 40px solid #061d39;
  background-size: cover;
}

.header-homepage .swiper-container .swiper-slide-image::after {
  content: ' ';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.header-homepage .swiper-container .swiper-text {
  position: absolute;
  bottom: 4rem;
  left: 10vw;
  font-weight: 300;
  color: white;
  z-index: 1000;
  font-size: 2.4rem;
}

.header-homepage .swiper-container .swiper-text .title {
  display: block;
  position: relative;
  margin-bottom: 2rem;
}

.header-homepage .swiper-container .swiper-text .title::before {
  content: ' ';
  position: absolute;
  bottom: -25px;
  left: -30px;
  width: 60px;
  height: 60px;
  background-image: url(../images/icon-heading-plus.svg);
}

.header-homepage .swiper-container .swiper-text .subtitle {
  font-size: 1.2rem;
  font-weight: 600;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .header-homepage {
    height: 600px;
  }
  .header-homepage .swiper-container .swiper-slide-image {
    width: 70%;
    border-left: 30px solid #061d39;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header {
    height: 400px;
  }
  .header .subpage-banner {
    width: 70%;
    border-left: 30px solid #061d39;
  }
  .header-homepage {
    height: 600px;
  }
  .header-homepage .swiper-container .swiper-slide-image {
    width: 70%;
    border-left: 30px solid #061d39;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    height: auto;
    text-align: center;
  }
  .header .subpage-banner {
    position: relative;
    width: 100%;
    height: 200px;
    margin: 2rem 0;
    border-left: none;
  }
  .header .subpage-banner::after {
    display: none;
  }
  .header .plus-overlay {
    bottom: -36px;
  }
  .header-homepage {
    height: auto;
  }
  .header-homepage .swiper-container {
    position: relative;
    width: 100%;
    margin: 2rem 0;
  }
  .header-homepage .swiper-container .swiper-slide-image {
    position: relative;
    width: 100%;
    height: 200px;
    border-left: none;
  }
  .header-homepage .swiper-container .swiper-text {
    display: none;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  z-index: 2000;
  padding: 0;
}

.navbar-dark .navbar-collapse {
  justify-content: flex-end;
}

.navbar-dark .navbar-nav .nav-link {
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.9rem;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #91ad1f;
}

.navbar-dark .navbar-nav .dropdown-menu {
  border-radius: 0;
}

.navbar-dark .navbar-nav .dropdown-item {
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid rgba(61, 58, 58, 0.2);
  white-space: normal;
  text-align: center;
  font-size: 0.9rem;
}

.navbar-dark .navbar-nav .dropdown-item:last-child {
  border-bottom: none;
}

.navbar-dark .navbar-nav .dropdown-item.active,
.navbar-dark .navbar-nav .dropdown-item:active {
  background-color: #91ad1f;
}

@media (min-width: 768px) {
  .navbar-dark .navbar-nav .dropdown-menu {
    width: 220px;
    left: 50%;
    padding: 0.75rem;
    transform: translateX(-50%);
  }
}

/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-dark .navbar-nav .nav-link {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }
  .navbar-dark .navbar-nav .dropdown-menu {
    width: 190px;
    left: 50%;
    padding: 0.5rem;
  }
  .navbar-dark .navbar-nav .dropdown-menu .dropdown-item {
    padding: 0.25rem 0.4rem;
    font-size: 0.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-dark .navbar-toggler {
    width: 100%;
    padding: 0.75rem 0rem;
    margin: 2rem 0 0 0;
    font-size: 0.9rem;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page-title {
  position: absolute;
  bottom: 2rem;
  left: 10vw;
  font-weight: 300;
  color: white;
  z-index: 1000;
}

.page-title::before {
  content: ' ';
  position: absolute;
  bottom: -25px;
  left: -30px;
  width: 60px;
  height: 60px;
  background-image: url(../images/icon-heading-plus.svg);
}

.page {
  width: 100%;
  padding: 3rem 0;
}

.page-homepage {
  position: relative;
  padding: 5rem 0;
  margin-bottom: 2rem;
  background-size: cover;
  background-position: center;
}

.page-homepage h3 {
  margin-top: 0;
}

.page-homepage::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
}

.swiper-container {
  width: 100%;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.sidenav h6 {
  font-weight: 600;
  color: #91ad1f;
  font-size: 1.2rem;
}

.sidenav .nav-link {
  padding: 0.5rem 0;
  font-size: 0.9rem;
}

.sidenav .nav-item.active .nav-link {
  color: #91ad1f;
}

.case-study-listing .listing-content {
  padding: 2rem;
  background-color: rgba(61, 58, 58, 0.1);
}

.case-study-listing .listing-content h3 {
  margin-top: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.case-study-listing .listing-content .action {
  color: #3d3a3a;
}

.case-study-listing .listing-content .action .fa {
  color: #91ad1f;
}

.gallery-hover {
  position: relative;
}

.gallery-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.hover-caption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  display: none;
  overflow: hidden;
}

.hover-caption .caption {
  position: absolute;
  color: white;
  text-align: center;
  padding: 15%;
  top: 50%;
  transform: translateY(-50%);
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .page-title {
    font-size: 2rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .page-title {
    font-size: 1.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page-title {
    position: relative;
    left: 1.5rem;
    bottom: 0rem;
    width: calc(100% - 3rem);
    text-align: left;
    font-size: 2.2rem;
  }
  .page {
    padding: 2rem 0.5rem;
  }
  .case-study-listing .listing-content {
    padding: 1.2rem;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  position: relative;
  background-color: #061d39;
  color: white;
  font-size: 0.7rem;
}

.footer .plus-overlay {
  right: 0;
  top: -54px;
}

.footer .f-logo {
  background-color: #00306b;
  text-align: center;
  justify-content: center;
}

.footer .f-logo a {
  display: flex;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-content: center;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #1f60af;
  text-decoration: none;
}

.footer .f-body {
  padding: 3rem;
}

.footer h6 {
  margin: 0 0 1rem;
  font-size: 0.8rem;
}

.footer .nav-link {
  padding: 0.4rem 0;
}

.footer .footnotes {
  padding-top: 2rem;
  font-size: 0.7rem;
  opacity: 0.4;
}

.footer .social {
  font-size: 1.8rem;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer .f-logo a {
    padding-top: 3rem;
    align-items: flex-start;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer .f-logo {
    padding: 2rem 0;
  }
  .footer .f-body {
    padding: 2rem;
  }
  .footer .plus-overlay {
    top: -36px;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
