/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/

	$color-blue-dark: rgb(6, 29, 57);
	$color-blue-med: rgb(0, 48, 107);
	$color-blue-light: rgb(31, 96, 175);
	$color-green: rgb(145, 173, 31);
	$color-grey: rgb(61, 58, 58);
	$color-white: rgb(255, 255, 255);
	$color-black: rgb(0, 0, 0);

	$font-default: 'Open Sans', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;
		font-family: $font-default;
		font-weight: 400;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6
	{
		margin: 2rem 0;
		font-weight: 600;
	}

	h1
	{
		margin: 0 0 2rem 0;
	}

	h6
	{
		margin: 2rem 0 0.5rem 0;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-blue-med;
	}

		a:hover
		{
			color: $color-blue-light;
		}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	.btn
	{
		 border-radius: 0;
	}

	.btn-primary
	{
		background-color: $color-blue-med;
		border: none;
	}

		.btn-primary:hover
		{
			background-color: $color-blue-light;
		}

	.plus-overlay
	{
		position: absolute;
		z-index: 2000;
	}

	blockquote
	{
		font-size: 1.3rem;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.plus-overlay
		{
			img
			{
				width: 40px;
			}
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		position: relative;
		height: 459px;
		padding: 2.5rem 1.5rem;

		background-color: $color-blue-med;

		.plus-overlay
		{
			right: 0;
			bottom: -54px;
		}

		.container-fluid
		{
			z-index: 4000;
			position: relative;
		}
	;

		.subpage-banner
		{
			position: absolute;
			width: 75%;
			height: 100%;
			top: 0;
			right: 0;
			z-index: 1;

			border-left: 40px solid $color-blue-dark;
			background-size: cover;
		}

			.subpage-banner::after
			{
				content: ' ';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;

				background-color: transparentize($color-black, 0.60);
			}
	}

	.header-homepage
	{
		height: 800px;

		.swiper-container
		{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			right: 0;
			z-index: 1;

			.swiper-slide
			{
				background-color: $color-blue-med;
			}

			.swiper-slide-image
			{
				position: absolute;
				top: 0;
				right: 0;
				width: 75%;
				height: 100%;

				border-left: 40px solid $color-blue-dark;
				background-size: cover;
			}

				.swiper-slide-image::after
				{
					content: ' ';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;

					background-color: transparentize($color-black, 0.60);
				}

			.swiper-text
			{
				position: absolute;
				bottom: 4rem;
				left: 10vw;

				font-weight: 300;
				color: $color-white;
				z-index: 1000;

				font-size: 2.4rem;

				.title
				{
					display: block;
					position: relative;
					margin-bottom: 2rem;
				}

				.title::before
				{
					content: ' ';
					position: absolute;
					bottom: -25px;
					left: -30px;
					width: 60px;
					height: 60px;

					background-image: url(../images/icon-heading-plus.svg);
				}

				.subtitle
				{
					font-size: 1.2rem;
					font-weight: 600;
				}
			}
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.header-homepage
		{
			height: 600px;

			.swiper-container
			{
				.swiper-slide-image
				{
					width: 70%;

					border-left: 30px solid $color-blue-dark;
				}
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.header
		{
			height: 400px;

			.subpage-banner
			{
				width: 70%;

				border-left: 30px solid $color-blue-dark;
			}
		}

		.header-homepage
		{
			height: 600px;

			.swiper-container
			{
				.swiper-slide-image
				{
					width: 70%;

					border-left: 30px solid $color-blue-dark;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header
		{
			height: auto;

			text-align: center;

			.subpage-banner
			{
				position: relative;
				width: 100%;
				height: 200px;
				margin: 2rem 0;

				border-left: none;
			}

				.subpage-banner::after
				{
					display: none;
				}

			.plus-overlay
			{
				bottom: -36px;
			}
		}

		.header-homepage
		{
			height: auto;

			.swiper-container
			{
				position: relative;
				width: 100%;

				margin: 2rem 0;

				.swiper-slide-image
				{
					position: relative;
					width: 100%;
					height: 200px;

					border-left: none;
				}

				.swiper-text
				{
					display: none;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-dark
	{
		z-index: 2000;
		padding: 0;

		.navbar-collapse
		{
			justify-content: flex-end;
		}

		.navbar-nav
		{
			.nav-link
			{
				padding: 0.5rem 1rem;

				color: $color-white;
				font-size: 0.9rem;
			}

			.show > .nav-link,
			.active > .nav-link,
			.nav-link.show,
			.nav-link.active
			{
				color: $color-green;
			}

			.dropdown-menu
			{
				border-radius: 0;
			}

			.dropdown-item
			{
				padding: 0.4rem 0.5rem;
				border-bottom: 1px solid transparentize($color-grey, 0.8);

				white-space: normal;
				text-align: center;
				font-size: 0.9rem;
			}

				.dropdown-item:last-child
				{
					border-bottom: none;
				}

			.dropdown-item.active,
			.dropdown-item:active
			{
				background-color: $color-green;
			}
		}
	}

	@media (min-width: 768px) {
		.navbar-dark
		{
			.navbar-nav
			{
				.dropdown-menu
				{
					width: 220px;
					left: 50%;
					padding: 0.75rem;
					transform: translateX(-50%);
				}
			}
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-dark
		{
			.navbar-nav
			{
				.nav-link
				{
					padding: 0.5rem 0.8rem;

					font-size: 0.8rem;
				}

				.dropdown-menu
				{
					width: 190px;
					left: 50%;
					padding: 0.5rem;

					.dropdown-item
					{
						padding: 0.25rem 0.4rem;

						font-size: 0.8rem;
					}
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-dark
		{
			.navbar-toggler
			{
				width: 100%;
				padding: 0.75rem 0rem;
				margin: 2rem 0 0 0;

				font-size: 0.9rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page-title
	{
		position: absolute;
		bottom: 2rem;
		left: 10vw;

		font-weight: 300;
		color: $color-white;
		z-index: 1000;
	}

		.page-title::before
		{
			content: ' ';
			position: absolute;
			bottom: -25px;
			left: -30px;
			width: 60px;
			height: 60px;

			background-image: url(../images/icon-heading-plus.svg);
		}

	.page
	{
		width: 100%;
		padding: 3rem 0;
	}

	.page-homepage
	{
		position: relative;
		padding: 5rem 0;
		margin-bottom: 2rem;

		background-size: cover;
		background-position: center;

		h3
		{
			margin-top: 0;
		}
	}

		.page-homepage::before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			background-color: transparentize($color-white, 0.25);
		}

	.swiper-container
	{
		width: 100%;
	}

		.swiper-container .swiper-slide img
		{
			width: 100%;
		}

	.sidenav
	{
		h6
		{
			font-weight: 600;
			color: $color-green;
			font-size: 1.2rem;
		}

		.nav-link
		{
			padding: 0.5rem 0;
			font-size: 0.9rem;
		}

		.nav-item.active .nav-link
		{
			color: $color-green;
		}
	}

	.case-study-listing
	{

		.listing-content
		{
			padding: 2rem;

			background-color: transparentize($color-grey, 0.90);

			h3
			{
				margin-top: 0;

				font-size: 1.2rem;
				font-weight: 600;
			}

			.action
			{
				color: $color-grey;

				.fa
				{
					color: $color-green;
				}
			}
		}
	}

	.gallery-hover
	{
		position: relative;
	}

	.gallery-link
	{
		position: relative;
		width: 100%;
		height: 100%;
	}

	.hover-caption
	{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.75);
		z-index: 10;
		display: none;
		overflow: hidden;

		.caption
		{
			position: absolute;
			color: white;
			text-align: center;
			padding: 15%;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.page-title
		{
			font-size: 2rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.page-title
		{
			font-size: 1.8rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page-title
		{
			position: relative;
			left: 1.5rem;
			bottom: 0rem;
			width: calc(100% - 3rem);

			text-align: left;
			font-size: 2.2rem;
		}

		.page
		{
			padding: 2rem 0.5rem;
		}

		.case-study-listing
		{
			.listing-content
			{
				padding: 1.2rem;
			}
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		position: relative;

		background-color: $color-blue-dark;

		color: $color-white;
		font-size: 0.7rem;

		.plus-overlay
		{
			right: 0;
			top: -54px;
		}

		.f-logo
		{
			background-color: $color-blue-med;

			text-align: center;
			justify-content: center;

			a
			{
				display: flex;
				width: 100%;
				height: 100%;
				align-self: center;
				justify-content: center;
			}
		}

		a
		{
			color: $color-white;
		}

		a:hover
		{
			color: $color-blue-light;
			text-decoration: none;
		}

		.f-body
		{
			padding: 3rem;
		}

		h6
		{
			margin: 0 0 1rem;
			font-size: 0.8rem;
		}

		.nav-link
		{
			padding: 0.4rem 0;
		}

		.footnotes
		{
			padding-top: 2rem;
			font-size: 0.7rem;

			opacity: 0.4;
		}

		.social
		{
			font-size: 1.8rem;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer
		{
			.f-logo
			{
				a
				{

					padding-top: 3rem;

					align-items: flex-start;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			.f-logo
			{
				padding: 2rem 0;
			}

			text-align: center;

			.f-body
			{
				padding: 2rem;
			}

			.plus-overlay
			{
				top: -36px;
			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}